const eventMap = {
  ITEM_GET_SUCCESS: {
    event_type: "item_detail_viewed",
    object_type: "item",
    object_uid: "data.uid",
    object_name: "object_name",
    postDelay: 750
  },
  SET_ITEM_SCREENER_ISPLAYING: {
    event_type: "play",
    object_type: "screener",
    object_uid: "screener.uid",
    object_name: "screener.name"
  },
  ITEM_SET_FILTER_PARAMS: {
    event_type: "item_set_filter_params",
    object_type: "filter",
    object_uid: "uid",
    object_name: "name"
  },
  ITEM_SET_USER_FILTER: {
    event_type: "item_set_user_filter",
    object_type: "filter",
    object_uid: "uid",
    object_name: "name"
  },
  POST_FAVORITE_SUCCESS: {
    event_type: "item_added_to_favorite",
    object_type: "item",
    object_uid: "data.item.uid",
    object_name: "data.item.title"
  },
  POST_PLAYLIST_SUCCESS: {
    event_type: "playlist_created",
    object_type: "playlist",
    object_uid: "data.uid",
    object_name: "data.name"
  },
  PATCH_ADD_SCREENERS_SUCCESS: {
    event_type: "screener_added_to_playlist",
    object_type: "playlist",
    object_uid: "playlist_uid",
    object_name: ""
  },
  USER_SET_PROFILE_REQUEST_SUCCESS: {
    event_type: "user_edited_profile",
    object_type: "user",
    object_uid: "data.uid",
    object_name: "data.email"
  },
  TRACK_UP_NEXT: {
    event_type: "user_set_up_next",
    object_type: "user_interaction",
    object_uid: "",
    object_name: ""
  }
};

export { eventMap };
