import {
  getUserInfo,
  getPageInfo,
  getTeamInfo,
  getSearchInfo,
  getFiltersInfo
} from "@/libvue/plugins/statistics/baseStats";

import Config from "#/config";
import { eventMap } from "@/libvue/plugins/statistics/statsEventMap.js";
import { Murdock } from "@/murdock/browser";
import { nextTick } from "vue";

const murdockService = new Murdock({
  host: Config.apiHost() ? Config.apiHost() : window.location.hostname,
  basePath: "api/v2/stats",
  protocol: Config.API().use_ssl ? "https" : "http",
  requestType: "beaconAPI"
});

export const rcActionStats = {
  install(app, options) {
    murdockService.initAutoTracking();
    options.stores.subscribe((mutation, state) => {
      if (mutation.payload && eventMap[mutation.type]) {
        nextTick(() => {
          setTimeout(
            () => {
              const payload = formatPayload(mutation, state, options.router);
              postActionStats(payload);
            },
            eventMap[mutation.type].postDelay
              ? eventMap[mutation.type].postDelay
              : 0
          );
        });
      }
    });
  }
};
const formatPayload = (m, state, router) => {
  const info = {};

  info.event_type = eventMap[m.type].event_type;
  if ("user_uid" in state.auth.user && state.auth.user.user_uid)
    info.user_info = getUserInfo(state.auth.user);
  info.page_info = getPageInfo(router.currentRoute.value);
  info.object_info = getOjectInfo(m);

  if (Config.team.enabled) {
    info.team_info = getTeamInfo(state.auth.user);
  }

  if (m.type == "CATALOG_ITEM_SEARCH_SUCCESS") {
    info.search_info = getSearchInfo(state);
  }

  if (m.type == "ITEM_SET_FILTER_PARAMS" || m.type == "ITEM_SET_USER_FILTER") {
    info.search_info = getFiltersInfo(state);
  }

  return info;
};
const getOjectInfo = (m) => {
  return {
    object_type: eventMap[m.type].object_type,
    object_uid: Object.byString(m.payload, eventMap[m.type].object_uid),
    object_name: Object.byString(m.payload, eventMap[m.type].object_name),
    object_metadata: m.payload.metadata ? m.payload.metadata : {}
  };
};
const postActionStats = (payload) => {
  murdockService.deferEvent(payload.event_type, payload);
};
Object.byString = function (o, s) {
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};
