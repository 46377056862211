import { createStore } from "vuex";

// root store
import root from "#/stores/root";

// apps store
import auth from "@/libvue/apps/auth/store";
import team from "@/libvue/apps/team/store";
import reports from "@/libvue/apps/team/reports/store";
import user from "@/libvue/apps/user/store";
import courses from "@/libvue/apps/courses/store";
import items from "@/libvue/apps/catalog/items/store";
import screeners from "@/libvue/apps/catalog/screeners/store";
import categories from "@/libvue/apps/catalog/categories/store";
import subscribers from "@/libvue/apps/subscribers/store";
import favorites from "@/libvue/apps/catalog/favorites/store";
import playlists from "@/libvue/apps/catalog/playlists/store";
import homepages from "@/libvue/apps/catalog/homepages/store";
import lineups from "@/libvue/apps/catalog/lineups/store";
import newsletter from "@/libvue/apps/newsletter/store";
import ratings from "@/libvue/components/rating/store";
import exports from "@/libvue/apps/catalog/exports/store";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
  modules: {
    root,
    // apps
    auth,
    team,
    reports,
    user,
    courses,
    items,
    screeners,
    categories,
    subscribers,
    favorites,
    playlists,
    homepages,
    lineups,
    ratings,
    newsletter,
    exports
  },
  strict: debug
});
